<template>
  <section class="profile-settings">
    <h2 class="profile-settings__heading">Profil d'utilisateur</h2>
    <div class="profile-settings__section">
      <div class="profile-settings__section-header">
        <h3 class="profile-settings__section-heading">Cordonnées Générales</h3>
        <span class="profile-settings__section-header-line"></span>
        <atom-button
          class="profile-settings__section-button"
          variant="dark-outline"
          @click="isMutable = true"
        >
          <span
            class="profile-settings__section-button-text"
            >Modifier</span
          >
          <atom-icon
            class="profile-settings__section-button-icon"
            icon="pencil"
          ></atom-icon>
        </atom-button>
      </div>
      <div class="profile-settings__section-body">
        <div class="profile-settings__section-item">
          <span class="profile-settings__section-item-title"
            >Email</span
          >
          <input
            type="text"
            class="profile-settings__section-item-input"
            v-model="newUser.email"
            disabled
          />
        </div>
        <div class="profile-settings__section-item">
          <span class="profile-settings__section-item-title">Prénom</span>
          <input
            type="text"
            class="profile-settings__section-item-input"
            v-model="newUser.firstname"
            :disabled="!isMutable"
          />
        </div>
        <div class="profile-settings__section-item">
          <span class="profile-settings__section-item-title">Nom</span>
          <input
            type="text"
            class="profile-settings__section-item-input"
            v-model="newUser.lastname"
            :disabled="!isMutable"
          />
        </div>
        <div class="profile-settings__section-item">
          <span class="profile-settings__section-item-title">Date de naissance</span>
          <input
            type="text"
            class="profile-settings__section-item-input"
            v-model="newUser.birthday"
            :disabled="!isMutable"
          />
        </div>
        <div class="profile-settings__section-item">
          <span class="profile-settings__section-item-title">Emploi</span>
          <select v-model="newUser.job" :disabled="!isMutable" class="profile-settings__section-item-select">
            <option value="journaliste">Journaliste</option>
            <option value="societe civile">Société civile</option>
            <option value="parlementaire">Parlmenetaire</option>
            <option value="chercheur">Chercheur</option>
          </select>
        </div>
        <div class="profile-settings__section-item">
          <span class="profile-settings__section-item-title">Organisme</span>
          <input
            type="text"
            class="profile-settings__section-item-input"
            v-model="newUser.organization"
            :disabled="!isMutable"  
          />
        </div>
      </div>
      <div v-if="isMutable" class="profile-settings__section-footer">
        <atom-button variant="primary lg" class="profile-settings__section-button" @click.prevent="updateUser()">Enregister</atom-button>
      </div>
    </div>
    <!-- <div class="profile-settings__section">
      <div class="profile-settings__section-header">
        <h3 class="profile-settings__section-heading">Connected Accounts</h3>
        <span class="profile-settings__section-header-line"></span>
      </div>
      <div class="profile-settings__section-body">
        <div class="profile-settings__section-item">
          <span class="profile-settings__section-item-title"
            >Connected to Google</span
          >
          <p class="profile-settings__section-item-paragraph">
            Connect account to log in to e-tachawor with Google
          </p>
          <button class="profile-settings__section-item-button">
            Disconnect
          </button>
        </div>
      </div>
    </div>
    <div class="profile-settings__section">
      <div class="profile-settings__section-header">
        <h3 class="profile-settings__section-heading">Disactivate account</h3>
        <span class="profile-settings__section-header-line"></span>
      </div>
      <div class="profile-settings__section-body">
        <atom-button
          class="
            profile-settings__section-button
            profile-settings__section-button--red
          "
          variant="red-outline"
        >
          <span class="profile-settings__section-button-text"
            >Deactivate account</span
          >
          <atom-icon
            class="profile-settings__section-button-icon"
            icon="delete-bin-2"
          ></atom-icon>
        </atom-button>
      </div>
    </div> -->
  </section>
</template>

<script setup>
import {useAuth} from "@/composables/auth";
import { authMutationTypes } from "@/store/types/mutations";
import {ref} from "vue";
import {useStore} from "vuex";
const {dispatch, commit} = useStore();
const {currentUser} = useAuth();
const newUser = ref({
  ...currentUser.value,
  birthday:currentUser.value.birthday?.slice(0,10),
  id:currentUser.value.userId
});
const isMutable = ref(false);
const updateUser = async () => {
  try {
    await dispatch('users/update', newUser.value);
    commit(`auth/${authMutationTypes.SET_CURRENT_USER}`, newUser.value);
    isMutable.value = false;
  } catch (err) {
    const {message} = err.response.data;
    console.log(message);
  }
}
</script>

<style lang="scss" scoped>
.profile-settings {
  @apply container mx-auto px-4 my-8;
  &__heading {
    @apply text-[20px] text-black font-medium
      mb-[58px];
  }
  &__section {
    &-header {
      @apply flex justify-between items-center gap-8
        mb-6;
      &-line {
        @apply flex-1 h-[1px] bg-[#D8D7D6];
      }
    }
    &-heading {
      @apply text-base text-[#C4C4C4] font-medium relative;
    }
    &-button {
      @apply flex items-center gap-[12px] border;
      &--red {
        .profile-settings__section-button {
          &-text {
            @apply text-[#FF3A33];
          }
          &-icon {
            @apply fill-[#FF3A33];
          }
        }
      }
      &-icon {
        @apply fill-[#012230];
      }
      &-text {
        @apply text-dark;
      }
    }
    &-body {
    }
    &-item {
      @apply mb-3;
      &-title {
        @apply text-base leading-[19px] font-medium capitalize
          block;
      }
      &-input {
        @apply border-gray-100 mt-1 py-2 px-3 rounded-md shadow-sm;
        &[disabled] {
          @apply border-none shadow-none mt-0 bg-transparent px-0
            text-[#C4C4C4];
        }
      }
      &-select {
        @apply mt-1 block w-[237px] py-2 px-3 border border-gray-100 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm;
        &[disabled]{
          @apply border-none shadow-none bg-transparent px-0 text-[#949393] text-base appearance-none;
        }
        option{
          @apply py-1 inline-block;
        }
      }
      &-paragraph {
        @apply text-[#C4C4C4] mt-1;
      }
      &-button {
        @apply text-[#C4C4C4] text-base font-medium
          py-1 px-6
          mt-3 mb-3
          border border-[#C4C4C4]
          rounded-md;
      }
    }
  }
}
</style>